<style lang="scss" scoped>
/deep/ .comp-asset-edit {
    margin-top: 6vh !important;
    max-width: 800px;
}
</style>

<template>
    <div>
        <el-dialog title="资产编辑" custom-class="c-el-dialog comp-asset-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="分类" prop="categoryId">
                            <el-cascader :ref="refCascader" popper-class="c-no-cascader-radio" v-model="dialogData.categoryId" :options="categoryActiveList" placeholder="请选择资产分类" 
                                :props="defaultProps" filterable @change="closeCascader"></el-cascader>
                            <el-button class="mrgl5" type="primary" size="small" plain @click="openAddType()">新增分类</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="18">
                        <el-form-item label="条形码">
                            <el-input v-model="dialogData.barCode" placeholder="请输入或扫描条形码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="资产名称" prop="name">
                            <el-input v-model="dialogData.name" placeholder="请输入资产名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="图片" prop="imageUrl">
                            <image-upload :imageUrl="dialogData.imageUrl" :folder="'assets'" @emitImageUrl="setPhoto"></image-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="是否有序列号">
                            <el-switch v-model="dialogData.hasSerialNumber" active-text="是" inactive-text="否"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="描述">
                            <el-input type="textarea" :rows="3" v-model="dialogData.description" placeholder="请输入描述"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>

        <!--分类编辑-->
        <type-edit :ref="refCategoryEdit" @refreshData="getRefreshData"></type-edit>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import configMixins from "@/mixins/config"
import ImageUpload from "@/components/ImageDragUpload"
import TypeEdit from "./typeEdit"
export default {
    name: "compAssetEdit",
    mixins: [configMixins],
    components: { ImageUpload, TypeEdit },
    data() {
        return {
            refCascader: "refAssetEditCascader",
            refCategoryEdit: "refAssetEditToCategoryEdit",
            showDialog: false,
            dialogData: {},
            defaultDialogData: {
                name: "",
                barCode: "",
                categoryId: null,
                imageUrl: "",
                hasSerialNumber: false,
                description: ""
            },
            dialogType: "",
            formRefName: "refAssetEdit",
            formRules: {
                categoryId: [{ required: true, message: '请选择资产分类', trigger: ['blur', 'change']}],
                name: [{ required: true, message: '请输入资产名称', trigger: 'blur'}],
                imageUrl: [{ required: true, message: '请上传资产图片', trigger: ['blur', 'change']}]
            },
            categoryActiveList: [],
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            }
        }
    },
    methods: {
        open(row) {
            this.AllInit()
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            if (row) {
                this.dialogType = this.createOrUpdate[1]
                // 编辑
                funInfomation.getAssetDetail({id: row.id}).then(res => {
                    this.dialogData = res
                    this.dialogData.categoryId = res.category && res.category.id || null
                    this.showDialog = true
                })
            } else {
                this.dialogType = this.createOrUpdate[0]
                this.showDialog = true
            }
        },
        async AllInit(){
            window.$common.fullLoading()
            await funInfomation.getAssetCategory().then(res => {
                window.$common.closeFullLoading()
                this.categoryActiveList = this.parseCategoryTree(res)
            })
        },
        parseCategoryTree(datas) {
            let trees = []
            for(let i in datas) {
                let temp = datas[i]
                if (datas[i].children && datas[i].children.length > 0) {
                    temp.children = this.parseCategoryTree(datas[i].children)
                } else {
                    delete temp.children
                }
                trees.push(temp)
            }
            return trees
        },
        setPhoto(item) {
            this.dialogData.imageUrl = item.url || ""
        },
        // 打开添加分类
        openAddType() {
            this.$refs[this.refCategoryEdit].open()
        },
        // 添加分类后重新加载
        async getRefreshData(data) {
            await this.AllInit()
            if (data && data.id) {
                this.dialogData.categoryId = data.id
                this.$forceUpdate()
            }
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]) {
                        // 创建
                        funInfomation.setAsset(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    } else {
                        // 修改
                        funInfomation.updateAsset(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    }
                } else {
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        closeCascader() {
            this.$refs[this.refCascader].dropDownVisible = false
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>